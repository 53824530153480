.article {
	position: relative;
	max-width: 64em;
	padding-top: 40px;
	padding-bottom: 50px;
}

.content {
	padding-left: 1.5em;
	padding-right: 1.5em;
}

@media (min-width: 36.5em) {
	.content {
		padding-left: 2em;
		padding-right: 2em;
	}
}

@media (min-width: 55em) {
	.content {
		padding-left: 1.25em;
		padding-right: 1.25em;
	}
}

@media (min-width: 82.5em) {
	.content {
		margin: 0 auto;
		width: 82.5em - 1.25em * 2;
	}
}

.header-wrapper + .main-container {
	padding-top: 100px;
}