.form-container .input-field-container:not(:first-child) {
	margin-top: 2em;
}

.form-body-container:not(:first-child) {
	padding-top: 30px;
}

.form-body-container:not(:last-child) {
	padding-bottom: 30px;
}

.form-container .form-body-container {
	transition: opacity .5s;
}

.form-container.is-loading .form-body-container {
	opacity: 0.4;
	pointer-events: none;
}

@media(min-width: 50em) {
	.form-body-container {
		column-count: 2;
		column-gap: 5em;
	}
}

.form-body-container .input-field-container {
	break-inside: avoid
}

.form-footer-container *:not(:first-child){
	margin-top: 30px;
}