.is-skeleton {
	animation-name: skeleton-loading;
	animation-duration: .5s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	border-radius: 4px;
	background-color: var(--color-quaternary);
}

@keyframes skeleton-loading {
	from {
		opacity: .7;
	}

	to {
		opacity: 1;
	}
}

.skeleton-title {
	height: 1em;
	margin-bottom: .375em;
	font-size: 2em;
}

.skeleton-date {
	font-size: .875em;
	height: 1em;
	width: 20em;
}

.skeleton-short-description {
	margin-top: 1.6em;
	margin-bottom: 1em;
	height: 1em;
	width: 90%;
}

@media(min-width: 50em) {
	.skeleton-form-container {
		column-count: 2;
		column-gap: 5em;
	}
}

.skeleton-input-field {
	height: 2em;
}

.skeleton-form-container {
	padding-top: 30px;
}

.skeleton-form-container .skeleton-input-field:not(:first-child) {
	margin-top: 2em;
}

.skeleton-form-container .skeleton-input-field {
	break-inside: avoid
}