.input-field-container {
	position: relative;
	border-bottom: 1px solid rgba(var(--color-text-rgb), .5);
	transition: border-color .15s;
}

.input-field-container.is-focused {
	border-bottom-color: var(--color-secondary);
}

.input-field-container.is-error {
	border-color: red;
}

.input-field-container label {
	font-weight: 700;
	text-transform: uppercase;
	transition: color .15s;
}

.input-field-container.is-focused label {
	color: var(--color-secondary);
}

.input-field-container input {
	height: 2em;
	width: 100%;
	padding: 0;
	border: none;
	background-color: transparent;
}

.input-field-container label + input {
	margin-top: .375em;
}

.input-field-container input::placeholder {
	opacity: .5;
	color: var(--color-text);
}

.input-field-container input:focus {
	color: var(--color-secondary);
	outline: none;
}