.register-form-container .form-container:not(:first-child) {
	margin-top: 2em;
}

.register-form-title {
	margin-bottom: .375em;
}

.register-form-title strong {
	color: var(--color-secondary);
}

.register-form-event-date {
	display: inline-block;
	padding: .25em 1em;
	font-size: .875em;
	line-height: 1.6;
	background-color: var(--color-quaternary);
	border-radius: 4px;
}