.photo-header-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 300px;
	max-height: 25vh;
}

.photo-header-container > img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}