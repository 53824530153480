.header-wrapper {
	position: relative;
	min-height: 60px;
	background-color: var(--color-primary);
	z-index: 10;
}

.header-content {
	line-height: 60px;
}

.header-logo-wrapper {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	min-height: 162px;
	overflow: hidden;
}

.header-logo {
	display: block;
	position: absolute;
	left: 50%;
	top: -14em;
	margin-left: -12em;
	width: 24em;
}

.header-logo img {
	display: block;
}