/* roboto-slab-regular - latin */
@font-face {
	font-family: 'Roboto Slab';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		 url('./assets/fonts/roboto-slab-v12-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
		 url('./assets/fonts/roboto-slab-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-slab-700 - latin */
@font-face {
	font-family: 'Roboto Slab';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		 url('./assets/fonts/roboto-slab-v12-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
		 url('./assets/fonts/roboto-slab-v12-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-regular - latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		 url('./assets/fonts/lato-v17-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
		 url('./assets/fonts/lato-v17-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-700 - latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		 url('./assets/fonts/lato-v17-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
		 url('./assets/fonts/lato-v17-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

:root {
	--color-text: rgb(50, 50, 50);
	--color-text-rgb: 50, 50, 50;
	--color-primary: rgb(202, 33, 40);
	--color-secondary: rgb(61, 125, 204);
	--color-tertiary: rgb(234, 139, 3);
	--color-quaternary: rgb(238, 238, 238);
	--font-primary: 'Lato', sans-serif;
	--font-secondary: 'Roboto Slab', serif;
}

body {
	font-family: var(--font-primary);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
}

* {
	box-sizing: border-box;
}

strong {
	font-weight: 700;
}

em {
	font-style: italic;
}

img,
embed,
object,
video {
	height: auto;
	max-width: 100%;
}

iframe {
	max-width: 100%;
}

p,
ul,
ol,
table,
address,
h1,
h2,
h3,
figure {
	font-size: 1em;
	line-height: 1.7;
	margin-top: 1.6em;
}

@media (min-width: 36.5em) {
	p,
	ul,
	ol,
	table,
	address,
	h1,
	h2,
	h3,
	figure {
		font-size: 1.0625em;
	}
}

h1,
h2,
h3 {
	font-family: var(--font-secondary);
	color: var(--color-text);
	line-height: 1.2;
	font-weight: 400;
}

h1 {
	margin-top: 0;
	font-size: 1.875em;
}

@media (min-width: 55em) {
	h1 {
		font-size: 2em;
	}
}

h2 {
	font-size: 1.375em;
}

@media (min-width: 55em) {
	h2 {
		font-size: 1.5em;
	}
}

h3 {
	font-size: 1.125em;
}

@media (min-width: 55em) {
	h3 {
		font-size: 1.375em;
	}
}

h1 a:link,
h1 a:visited,
h2 a:link,
h2 a:visited,
h3 a:link,
h3 a:visited {
	color: inherit;
	text-decoration: none;
}

ul,
ol {
	padding-left: 1.2em;
}

ul {
	list-style-type: circle;
}

ol {
	list-style-type: decimal;
}

a:link,
a:visited {
	color: var(--color-primary);
	text-decoration: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="submit"],
textarea {
	font-family: inherit;
	font-size: inherit;
	border: none;
	padding: 0;
	/* background-color: rgb(238, 238, 238); */
}