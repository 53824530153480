.message-container {
	padding: 1em;
	background-color: var(--color-quaternary);
	border-left: 8px solid rgba(var(--color-text-rgb), .5);
	border-radius: 4px;
}

.message-container.message-type-error {
	background-color: rgba(255, 0, 0, .2);
	border-color: rgba(255, 0, 0, .8);
}

.message-container.message-type-success {
	background-color: rgba(0, 255, 70, .2);
	border-color: rgba(0, 255, 70, .8);
}

.message-container p:first-child {
	margin-top: 0;
}

.message-container p:last-child {
	margin-bottom: 0;
}